<template>
  <div>
    <b-card title="New Field" sub-title="Add new template field">
      <b-card-body>
        <b-form @submit="onSubmitFormTFN">
          <b-form-row>
            <b-col>
              <b-form-group
                id="input-group-field-label"
                label="Label"
                label-for="formTFN_label"
                label-class="font-weight-bold text-uppercase"
                description="Template field label."
              >
                <b-form-input
                  id="formTFN_label"
                  v-model="formTFN.label"
                  type="text"
                  required
                  placeholder="Enter label"
                  @keyup="labelToKey($event)"
                  @blur="labelToKey($event)"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                id="input-group-field-key"
                label="Key (Optional)"
                label-for="formTFN_key"
                label-class="font-weight-bold text-uppercase"
                description="Template field key."
              >
                <b-form-input
                  id="formTFN_key"
                  v-model="formTFN.key"
                  type="text"
                  required
                  readonly
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-group
            id="input-group-description"
            label="Description"
            label-for="formTFN_description"
            label-class="font-weight-bold text-uppercase"
            description="Template field description."
          >
            <b-form-textarea
              id="formTFN_description"
              v-model="formTFN.description"
              placeholder="Description for the field"
              rows="3"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-form-group id="input-group-alternates-new">
            <a
              class="btn btn-success font-size-sm text-uppercase"
              @click="addAlternate()"
            >
              <i class="flaticon2-add-1"></i> Add Alternate Name
            </a>
            <div
              class="ml-5 mt-3 mb-3"
              v-for="(alternate, aIndex) in formTFN.alternates"
              :key="aIndex"
            >
              <b-form-row>
                <b-col cols="6">
                  <b-form-input
                    v-model="alternate.label"
                    placeholder="Alternate name"
                  ></b-form-input>
                </b-col>
                <b-col cols="6" class="mt-3">
                  <a
                    @click="removeAlternate(aIndex)"
                    v-show="
                      aIndex || (!aIndex && formTFN.alternates.length > 0)
                    "
                  >
                    <i class="fas fa-minus-circle"></i>
                  </a>
                </b-col>
              </b-form-row>
            </div>
          </b-form-group>

          <b-form-group id="input-group-validators-new">
            <a
              class="btn btn-success font-size-sm text-uppercase"
              @click="addValidator()"
            >
              <i class="flaticon2-add-1"></i> Add Validator
            </a>

            <div
              class="ml-5 mt-3 mb-3"
              v-for="(validator, vIndex) in formTFN.validators"
              :key="vIndex"
            >
              <b-form-row>
                <b-col cols="12">
                  <b-form-row>
                    <b-col cols="6">
                      <input type="hidden" v-model="validator.rule" />

                      <label class="font-weight-bolder text-uppercase">
                        {{ validator.label }}
                      </label>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                      <a
                        @click="removeValidator(vIndex)"
                        v-show="
                          vIndex || (!vIndex && formTFN.validators.length > 0)
                        "
                      >
                        <i class="fas fa-minus-circle"></i>
                      </a>
                    </b-col>
                  </b-form-row>

                  <b-form-row v-show="validator.params">
                    <b-col cols="12">
                      <!-- params for: {{ validator.label }} {{ validator.params }} -->

                      <b-form-row>
                        <b-col
                          cols="6"
                          v-if="hasProp(validator.params, 'value')"
                        >
                          <b-form-input
                            v-model="validator.params.value"
                            :placeholder="validator.label + ' value'"
                            class="mt-3 mb-3"
                          ></b-form-input>
                        </b-col>
                        <b-col
                          cols="6"
                          class="mt-5"
                          v-if="hasProp(validator.params, 'case_sensitive')"
                        >
                          <b-form-checkbox
                            id="validator_params_case_sensitive"
                            v-model="validator.params.case_sensitive"
                            value="1"
                            unchecked-value="0"
                            class="text-uppercase"
                            size="lg"
                          >
                            Case Sensitive
                          </b-form-checkbox>
                        </b-col>
                      </b-form-row>
                    </b-col>
                  </b-form-row>
                </b-col>
              </b-form-row>
            </div>

            <!-- <b-form-checkbox-group
              id="formTFN_validators_rules"
              v-model="formTFN.validators.rules"
              stacked
              class="text-uppercase"
            >
              <b-form-checkbox size="lg" value="required"
                >Required</b-form-checkbox
              >
              <b-form-checkbox size="lg" value="unique">Unique</b-form-checkbox>
              <b-form-checkbox size="lg" value="starts_with"
                >Starts with</b-form-checkbox
              >
              <div
                class="ml-5"
                v-if="this.formTFN.validators.rules.includes('starts_with')"
              >
                <b-form-row>
                  <b-col cols="6">
                    <b-form-input
                      id="formTFN_validators_params_starts_with_value"
                      v-model="formTFN.validators.params.starts_with.value"
                      placeholder="starts with"
                      class="mt-3 mb-3"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6" class="mt-5">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="formTFN_validators_params_starts_with_case_sensitive"
                        v-model="
                          formTFN.validators.params.starts_with.case_sensitive
                        "
                        value="1"
                        class="custom-control-input"
                      />
                      <label
                        class="custom-control-label text-uppercase"
                        for="formTFN_validators_params_starts_with_case_sensitive"
                        >Case Sensitive</label
                      >
                    </div>
                  </b-col>
                </b-form-row>
              </div>
              <b-form-checkbox size="lg" value="ends_with"
                >Ends with</b-form-checkbox
              >
              <div
                class="ml-5"
                v-if="this.formTFN.validators.rules.includes('ends_with')"
              >
                <b-form-row>
                  <b-col cols="6">
                    <b-form-input
                      id="formTFN_validators_params_ends_with_value"
                      v-model="formTFN.validators.params.ends_with.value"
                      placeholder="ends with"
                      class="mt-3 mb-3"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6" class="mt-5">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="formTFN_validators_params_ends_with_case_sensitive"
                        v-model="
                          formTFN.validators.params.ends_with.case_sensitive
                        "
                        value="1"
                        class="custom-control-input"
                      />
                      <label
                        class="custom-control-label text-uppercase"
                        for="formTFN_validators_params_ends_with_case_sensitive"
                        >Case Sensitive</label
                      >
                    </div>
                  </b-col>
                </b-form-row>
              </div>
            </b-form-checkbox-group> -->
          </b-form-group>

          <b-button type="submit" variant="primary" ref="bt_save_f"
            >Save</b-button
          >
          <b-button
            class="ml-2"
            type="button"
            variant="danger"
            @click="toggleAddField"
          >
            Cancel
          </b-button>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { CREATE_TEMPLATE_FIELD } from "@/core/services/store/template.module";
import "@/lib/filters";

export default {
  name: "TemplateFieldNew",
  /*props: {
    template: Object
  },*/
  data() {
    return {
      formTFN: {
        uuid: "",
        label: "",
        key: "",
        description: "",
        alternates: [],
        /*validators: {
          rules: [],
          params: {
            starts_with: { value: "", case_sensitive: 0 },
            ends_with: { value: "", case_sensitive: 0 }
          }
        },*/
        validators: [],
        template_uuid: ""
      }
    };
  },
  methods: {
    addAlternate() {
      this.$parent.addAlternate(this.formTFN);
    },

    removeAlternate(index) {
      this.$parent.removeAlternate(this.formTFN, index);
    },

    addValidator() {
      this.$parent.addValidator(this.formTFN);
    },

    removeValidator(index) {
      this.$parent.removeValidator(this.formTFN, index);
    },

    hasProp(obj, prop) {
      if (!obj) {
        return false;
      }

      return obj.hasOwnProperty(prop);
    },

    labelToKey(event) {
      this.$parent.labelToKey(this.formTFN, event);
    },

    toggleAddField() {
      this.$parent.toggleAddField();
    },

    onSubmitFormTFN(evt) {
      evt.preventDefault();
      const buttonF = this.$refs["bt_save_f"];
      buttonF.classList.add("spinner", "spinner-light", "spinner-right");

      this.formTFN.template_uuid = this.$parent.currentTemplate.uuid;

      setTimeout(() => {
        this.$store
          .dispatch(CREATE_TEMPLATE_FIELD, this.formTFN)
          .then(() => {
            this.resetFormTFN();

            this.toggleAddField();

            this.$swal.fire(
              `Created!`,
              "Template field created successfully.",
              "success"
            );
          })
          .catch(() => {
            this.$swal.fire(`Error!`, "Template field create failed!", "error");
          });

        buttonF.classList.remove("spinner", "spinner-light", "spinner-right");
      }, 2000);
    },

    resetFormTFN() {
      // Reset our form values
      this.formTFN = {
        uuid: "",
        label: "",
        key: "",
        description: "",
        alternates: [],
        /*validators: {
          rules: [],
          params: {
            starts_with: { value: "", case_sensitive: 0 },
            ends_with: { value: "", case_sensitive: 0 }
          }
        },*/
        validators: [],
        template_uuid: ""
      };

      this.showFormTFN = false;
    }
  }
};
</script>
