<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            <b-form @submit="onSubmitFormT" inline>
              <b-form-input
                id="formT_name"
                v-model="formT.name"
                type="text"
                required
                placeholder="Set Template Name"
              ></b-form-input>

              <b-button
                type="submit"
                variant="primary"
                ref="bt_save_t"
                class="ml-5"
              >Save</b-button
              >
            </b-form>
          </span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm"></span>
        </h3>
        <div class="card-toolbar">
          <a
            class="btn btn-success font-size-sm text-uppercase"
            @click="toggleAddField"
            v-show="currentTemplate.uuid"
          >
            <i class="flaticon2-add-1"></i> Add Field
          </a>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">

        <h3>Template settings</h3>

        <b-form-checkbox
            id="key_as_header"
            v-model="formT.key_as_header"
            name="key_as_header"
            class="font-weight-normal"
            :value="true"
            :unchecked-value="false"
        >
          Key as header
        </b-form-checkbox>

        <h3 class="mt-10" v-if="currentTemplate.fields.length > 0">Fields</h3>

        <TemplateFieldNew v-show="showFormTFN"></TemplateFieldNew>

        <span class="p-5 mr-2"></span>

        <div v-for="(field, f) in currentTemplate.fields" :key="f">
          <b-card :title="field.label">
            <div class="float-right">
              <a
                class="btn btn-success font-size-sm text-uppercase"
                @click="editTemplateField(field.uuid, true)"
                v-show="!activeEdits.includes(field.uuid)"
              >
                <i class="flaticon2-edit"></i>
                Edit
              </a>

              <a
                class="btn btn-success font-size-sm text-uppercase"
                @click="editTemplateField(field.uuid, false)"
                v-show="activeEdits.includes(field.uuid)"
              >
                <i class="flaticon2-cancel"></i>
                Cancel
              </a>

              <a
                class="btn btn-danger font-size-sm text-uppercase ml-5"
                @click="deleteTemplateField(field.uuid)"
              >
                <i class="flaticon2-trash"></i> Delete
              </a>
            </div>
            <b-card-text>
              {{ field.description }}

              <TemplateFieldEdit
                v-bind:field="field"
                v-show="activeEdits.includes(field.uuid)"
              ></TemplateFieldEdit>
            </b-card-text>
          </b-card>

          <span class="p-5 mr-2"></span>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <div class="card card-custom card-stretch gutter-b" v-if="currentTemplate.uuid.length > 0">
      <!--begin:Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">Webhooks</h3>
        <div class="card-toolbar">
          <a
            class="btn btn-success font-size-sm text-uppercase"
            v-if="currentTemplate.uuid"
            @click="addNewWebhook(currentTemplate.uuid)"
          >
            <i class="flaticon2-add-1"></i> Add Webhook
          </a>
        </div>
      </div>
      <!--end:Header-->
      <!--begin:Body-->
      <div class="card-body pt-0">
        <table class="table" v-if="typeof currentTemplate.webhooks !== 'undefined' && currentTemplate.webhooks.length > 0">
          <thead>
          <tr>
            <th>URL</th>
            <th class="w-110px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(webhook, index) in currentTemplate.webhooks" :key="index">
            <td>{{ webhook.url }}</td>
            <td class="pl-0 pr-0 text-right">
              <a
                  class="btn btn-danger font-weight-bolder font-size-sm text-uppercase ml-5"
                  @click="deleteWebhook(webhook.id, currentTemplate.uuid)"
              >
                <i class="flaticon2-trash"></i> Delete
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <v-alert type="info" v-if="typeof currentTemplate.webhooks === 'undefined' || currentTemplate.webhooks.length < 1">
          There are no webhooks for this template
        </v-alert>
      </div>
      <!--end:Body-->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  SAVE_TEMPLATE,
  RESET_TEMPLATE,
  DELETE_TEMPLATE_FIELD,
  DELETE_TEMPLATE_WEBHOOK,
  CREATE_TEMPLATE_WEBHOOK
} from "@/core/services/store/template.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TemplateFieldNew from "@/view/pages/templates/components/TemplateFieldNew";
import TemplateFieldEdit from "@/view/pages/templates/components/TemplateFieldEdit";
import slugify from "slugify";
import { S } from "@/lib/constants";

export default {
  name: "TemplateBuilder",
  components: {
    TemplateFieldNew,
    TemplateFieldEdit
  },
  data() {
    return {
      formT: {
        uuid: "",
        name: "",
        key_as_header: false
      },

      showFormTFN: false,
      activeEdits: []
    };
  },
  mounted() {
    // clear existing errors
    this.$store.dispatch(RESET_TEMPLATE);

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Template Builder" }]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });

    if (this.currentTemplate.uuid) {
      this.formT.uuid = this.currentTemplate.uuid;
      this.formT.name = this.currentTemplate.name;
    }
  },
  methods: {
    addAlternate(form) {
      form.alternates.push({ label: "" });
    },

    removeAlternate(form, index) {
      form.alternates.splice(index, 1);
    },

    addValidator(form) {
      this.$swal.fire({
        title: "Select validator",
        input: "select",
        inputOptions: {
          required: "Required",
          Unique: "Unique",
          starts_with: "Starts With",
          ends_with: "Ends With",
          contains: "Contains",
          max_length: "Max Length",
          min_length: "Min Length",
          numerical_only: "Numerical Only",
          letters_only: "Letters Only",
          email: "Email",
          regex: "Regex"
        },
        inputPlaceholder: "Select a validator",
        inputAttributes: {
          class: "form-control"
        },
        showCancelButton: true,
        inputValidator: value => {
          var params = null;
          switch (value) {
            case "starts_with":
            case "ends_with":
              params = { value: "", case_sensitive: 0 };
              break;
            case "contains":
            case "max_length":
            case "min_length":
            case "regex":
              params = { value: "" };
              break;
          }

          var label = S(value).humanize().s;

          form.validators.push({ rule: value, label: label, params: params });
        }
      });
    },

    removeValidator(form, index) {
      form.validators.splice(index, 1);
    },

    labelToKey(form, event) {
      form.key = slugify(event.target.value, {
        replacement: "_",
        lower: true
      });
    },

    toggleAddField() {
      this.showFormTFN = this.showFormTFN == true ? false : true;
    },

    onSubmitFormT(evt) {
      evt.preventDefault();

      const buttonT = this.$refs["bt_save_t"];
      buttonT.classList.add("spinner", "spinner-light", "spinner-right");

      if (this.currentTemplate.uuid) {
        this.formT.uuid = this.currentTemplate.uuid;
      }

      setTimeout(() => {
        this.$store
          .dispatch(SAVE_TEMPLATE, this.formT)
          .then(() => {
            this.$swal.fire(
              `Saved!`,
              "Template saved successfully.",
              "success"
            );
          })
          .catch(() => {});

        buttonT.classList.remove("spinner", "spinner-light", "spinner-right");
      }, 2000);
    },

    editTemplateField(uuid, edit) {
      if (edit) {
        this.activeEdits.push(uuid);
      } else {
        this.activeEdits = Vue.lodash.remove(this.activeEdits, aEdit => {
          return aEdit != uuid;
        });
      }
    },

    addNewWebhook(uuid) {
      this.$swal.fire({
        title: "Add new Webhook",
        input: 'url',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Apply"
      }).then(result => {
        if (result.isConfirmed) {
          const {value: url} = result;
          this.$store
            .dispatch(CREATE_TEMPLATE_WEBHOOK, {
              uuid, url
            })
            .then(() => {
              this.$swal.fire(
                `Created!`,
                "Webhook created successfully.",
                "success"
              );
            })
            .catch((err) => {
              const errors = err.response.data.errors.url;
              this.$swal.fire(`Error!`, errors.join("\n"), "error");
            });
        }
      });
    },

    deleteTemplateField(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.isBusy = true;
          this.$store
            .dispatch(DELETE_TEMPLATE_FIELD, uuid)
            .then(() => {
              this.$swal.fire(
                `Deleted!`,
                "Template field deleted successfully.",
                "success"
              );
            })
            .catch(() => {});
        }
      });
    },

    deleteWebhook(webhookUuid, templateUuid) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!----"
      }).then(result => {
        if (result.isConfirmed) {
          this.isBusy = true;
          this.$store
            .dispatch(DELETE_TEMPLATE_WEBHOOK, {webhookUuid, templateUuid})
            .then(() => {
              this.$swal.fire(
                `Deleted!`,
                "Template field deleted successfully.",
                "success"
              )
            })
            .catch(() => {});
        }
      });
    }
  },
  computed: {
    ...mapGetters(["currentTemplate"])
  },
  watch: {
    currentTemplate(newValue) {
      //console.log(`Updating from ${oldValue.uuid} to ${newValue.uuid}`);

      // Do whatever makes sense now
      if (newValue.uuid) {
        this.formT.uuid = newValue.uuid;
        this.formT.name = newValue.name;
        this.formT.key_as_header = newValue.key_as_header;
      }
    }
  }
};
</script>
